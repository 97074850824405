<template>
    <form novalidate v-bind="$attrs" @submit.prevent="emit('submit')" @keypress.enter="(keypressEnter, emit('enter'))">
        <div
            v-if="errorMessages.length"
            class="bg-rose-100 relative text-rose-600 py-2.5 px-5 mb-4 rounded font-medium border-l-4 border-l-rose-500 pr-14 w-full"
        >
            <div v-for="(item, index) in errorMessages" :key="index">
                {{ item }}
            </div>
            <button
                class="absolute right-0.5 top-1.5 rounded-full text-center w-8 h-8 mx-2 transition duration-300 hover:text-rose-700 hover:bg-rose-200 focus:ring focus:ring-rose-300"
                @click="errorMessages.length = 0"
            >
                <Icon name="close" />
            </button>
        </div>

        <slot />
    </form>
</template>

<script setup>
defineProps({
    keypressEnter: {
        type: Function,
        default() {
            return () => {};
        },
    },
});

const emit = defineEmits(['submit', 'enter']);

const errorMessages = ref([]);
const fields = ref([]);

provide('fields', fields.value);

function isElementInViewport(el) {
    if (!el) return false;

    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

function handleErrors(errorMessage, global = true) {
    const errors = JSON.parse(errorMessage);
    const messages = [];
    let scrolled = false;

    for (const key in errors) {
        const field = fields.value.find((field) => field.name === key);

        if (field) {
            if (field.element && !scrolled && !isElementInViewport(field.element)) {
                scrolled = true;
                const { top } = field.element.getBoundingClientRect();
                window.scrollTo({
                    top: window.scrollY + top - 240,
                    behavior: 'smooth',
                });
            }

            field.messages.push(errors[key].message);
        } else if (global) {
            messages.push(errors[key].message);
        }
    }

    errorMessages.value = messages;
}

defineExpose({
    handleErrors,
    errorMessages,
    fields,
});
</script>
